// eslint-disable-next-line no-restricted-imports
import {
  MarketModalPartial as MarketModalPartialBinding,
  MarketModalFull as MarketModalFullBinding,
  MarketBlade as MarketBladeBinding,
  MarketDialog as MarketDialogBinding,
} from '@market/react';
import React, { useCallback } from 'react';

const MARKET_MODAL_CLOSE_ANIMATION_TIME_MS = 160;

type AnyModalProps =
  | React.ComponentPropsWithRef<typeof MarketModalPartialBinding>
  | React.ComponentPropsWithRef<typeof MarketModalFullBinding>
  | React.ComponentPropsWithRef<typeof MarketBladeBinding>
  | React.ComponentPropsWithRef<typeof MarketDialogBinding>;

function createMarketComponent<P extends AnyModalProps, R>(
  ModalComponent: React.FunctionComponent<P>
) {
  // eslint-disable-next-line react/display-name
  const WrapperComponent = React.forwardRef<R, P>(
    ({ children, className, onMarketDialogDismissed, ...rest }, ref) => {
      const onDismissed = useCallback(
        (event: never) => {
          setTimeout(() => {
            onMarketDialogDismissed?.(event);
          }, MARKET_MODAL_CLOSE_ANIMATION_TIME_MS);
        },
        [onMarketDialogDismissed]
      );

      const componentJSX = (
        // @ts-ignore Rest parameters are valid here.
        <ModalComponent
          ref={ref}
          trapFocus={true} // We are defaulting trapFocus attribute to true. The end user of a modal can still explicitly set trapFocus to false if they'd like to.
          {...rest}
          className={className}
          onMarketDialogDismissed={onDismissed}
        >
          {children}
        </ModalComponent>
      );

      return componentJSX;
    }
  );

  return WrapperComponent;
}

const MarketModalFull = createMarketComponent<
  React.ComponentPropsWithRef<typeof MarketModalFullBinding>,
  globalThis.HTMLMarketModalFullElement
>(MarketModalFullBinding);

const MarketModalPartial = createMarketComponent<
  React.ComponentPropsWithRef<typeof MarketModalPartialBinding>,
  globalThis.HTMLMarketModalPartialElement
>(MarketModalPartialBinding);

const MarketBlade = createMarketComponent<
  React.ComponentPropsWithRef<typeof MarketBladeBinding>,
  globalThis.HTMLMarketBladeElement
>(MarketBladeBinding);

const MarketDialog = createMarketComponent<
  React.ComponentPropsWithRef<typeof MarketDialogBinding>,
  globalThis.HTMLMarketDialogElement
>(MarketDialogBinding);

MarketModalFull.displayName = 'MarketModalFull';
MarketModalPartial.displayName = 'MarketModalPartial';
MarketBlade.displayName = 'MarketBlade';
MarketDialog.displayName = 'MarketDialog';

export { MarketModalFull, MarketModalPartial, MarketBlade, MarketDialog };
